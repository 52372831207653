import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Image from "../../composants/image/Image"
import LayoutTutorielFR from "../../composants/layout/fr/LayoutTutorielFR"
import Lien from "../../composants/navigation/Lien"
import Section from "../../composants/layout/Section"

import {
  obtenirImage,
  obtenirImages,
  obtenirPage,
  obtenirCartesLiensVersAutresPages,
} from "../../js/client-es/utils"
import fragmentsImages from "../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pageDecouverteNebuleusePlanetaire: allPagesCompilationYaml(
      filter: { code: { eq: "decouverteNebuleusePlanetaire" } }
    ) {
      ...FragmentPageYaml
    }
    pagesPourAllerPlusLoin: allPagesCompilationYaml(
      filter: {
        code: {
          in: ["paletteHubble12", "paletteHubble22", "galerieNebuleuses"]
        }
      }
      sort: { fields: ordre_carte, order: ASC }
    ) {
      ...FragmentCarteLienVersPage
    }
    imagesPaysage: allImagesCompilationYaml(
      filter: {
        code: {
          in: ["articlesStargazing", "ngc2070Tarentule", "articlesAladin"]
        }
      }
    ) {
      ...FragmentImagePaysage
    }
    imagesPaysageMoyen: allImagesCompilationYaml(
      filter: { code: { in: ["articlesFogl1Telescope1m"] } }
    ) {
      ...FragmentImagePaysageMoyen
    }
    imagesGif: allImagesCompilationYaml(
      filter: { code: { in: ["articlesAnimationDecouverteFogl1"] } }
    ) {
      ...FragmentImageEtLegendeGif
    }
  }
`

export default function DecouverteNebuleusePlanetaire() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(
    resultatsRequete,
    "pageDecouverteNebuleusePlanetaire"
  )
  const pagesPourAllerPlusLoin = obtenirCartesLiensVersAutresPages(
    resultatsRequete,
    "pagesPourAllerPlusLoin"
  )
  const images = obtenirImages(
    resultatsRequete,
    "imagesPaysage",
    "imagesPaysageMoyen",
    "imagesGif"
  )

  return (
    <LayoutTutorielFR
      page={page}
      pagesPourAllerPlusLoin={pagesPourAllerPlusLoin}
    >
      <Section titre="Les nébuleuses planétaires">
        <p>
          Une nébuleuse planétaire est un objet astronomique ayant l’aspect d’un
          disque. En raison de cet aspect, semblable à celui des planètes,
          l’adjectif « planétaire » a été choisi. Mais on sait maintenant que
          les nébuleuses planétaires n’ont en fait aucun rapport avec les
          planètes : il s’agit d’une nébuleuse en émission constituée d’une
          coquille de gaz en expansion éjectée par une étoile centrale en fin de
          vie.
        </p>
      </Section>
      <Section titre="Contexte de la découverte">
        <p>
          Par convention internationale, tout nouvel objet céleste découvert
          porte le nom de son découvreur, suivi d’un numéro. C’est un véritable
          rêve de passionné. Avoir son nom parmi les étoiles pour l’éternité…
          wow. Les amateurs qui ont réussi ce petit exploit sont très peu
          nombreux, peut-être moins d’une dizaine en France.
        </p>
        <p>
          Découvrir un objet céleste nouveau est un graal extrêmement difficile
          à atteindre, d’autant plus que les équipements des amateurs restent
          bien modestes face à ceux des scientifiques. Cependant, dans
          l’hémisphère Sud, tout est encore jouable, mais pas pour très
          longtemps encore car de plus en plus d’observatoires pilotés à
          distance sont installés au Chili. Après avoir manqué de peu une
          occasion sur une acquisition réalisée là-bas en 2016, j’ai décidé de
          retenter ma chance sur mon voyage 2018 en visant une zone située dans
          la constellation du Centaure, a priori vierge de toute exploration
          approfondie. Malheureusement, mes trouvailles étaient déjà toutes
          référencées… Nouvelle déconvenue.
        </p>
        <p>
          Mais j’avais aussi décidé de réaliser une image de la bien connue
          nébuleuse de la Tarentule (NGC 2070), située dans le Grand Nuage de
          Magellan. Cette galaxie, très proche de notre Voie lactée, est
          parfaitement visible à l’œil nu depuis l’hémisphère Sud. Je n’y
          cherchais alors rien de particulier, si ce n’est réaliser une belle
          image.
        </p>
        <Image
          image={obtenirImage(images, "articlesStargazing")}
          langue={page.langue}
        />
        <p>
          Pour réaliser l’image, j’ai décidé d’utiliser une technique d’imagerie
          bien connue mais très très peu utilisée sur cet objet céleste, ce qui
          m’a permis de réaliser la découverte. Elle a la particularité de
          filtrer très sévèrement les ondes lumineuses émises par les astres
          (surtout avec les filtres que j’emploie, des filtres de 3nm de bande
          passante).
        </p>
        <p>
          De retour à la maison, une petite tache bleue m’intrigue en traitant
          l’image sur mon ordinateur. Elle apparaît un peu dans
          l’Hydrogène-alpha (Ha), beaucoup dans l’Oxygène III ([OIII]), et pas
          du tout dans le Soufre II ([SII]), comme le montre l’animation
          suivante :
        </p>
        {/*les gifs ne sont pas gérés par gatsby image, il faut leur passer le chemin (src) en dur
        Ici on va cherche la description de l'image dans les fichiers Yaml, mais on affiche le gif se trouvant
        dans le répertoire static*/}
      </Section>
      <Image
        image={obtenirImage(images, "articlesAnimationDecouverteFogl1")}
        src="/ressources/gif/articlesAnimationDecouverteFogl1.gif"
        largeur="282"
        langue={page.langue}
      />
      <Section titre="Identification de l’objet">
        <p>
          Ne m’attendant à rien tant la zone a été ratissée, je décide par
          simple curiosité de chercher son petit nom dans la base de données
          Simbad du centre de données astronomiques de Strasbourg. Cette base de
          données est une référence mondiale et répertorie tous les objets
          astronomiques connus dans le ciel : nature, coordonnées, etc. Et je
          n’y trouve rien… de rien.
        </p>
        <Image
          image={obtenirImage(images, "articlesAladin")}
          langue={page.langue}
        />
        <p>
          Sous le curseur violet (au milieu), ma découverte : elle ne semble pas
          connue !
        </p>
        <p>
          Perplexe, je continue mes vérifications dans d’autres bases de
          données. Toujours rien. Je vérifie mes 7 heures d’acquisitions à la
          recherche d’un défaut. Tout est correct et les signaux sont francs :
          la nébuleuse existe bien.
        </p>
      </Section>
      <Section titre="Echanges avec Pascal Le Dû">
        <p>
          Je prends ma plus belle plume et je contacte Agnès Acker, puis Pascal
          Le Dû, le nouveau référent national sur le sujet pour lui faire part
          de ma trouvaille. Mon palpitant est à son maximum, je me relis
          plusieurs fois. Voici ce que je lui envoie :
        </p>
        <div className="conteneur-italique">
          <p>«</p>
          <p>Bonjour Pascal,</p>
          <p>
            De retour du Chili où je suis parti avec tout mon matériel pour
            l’astrophotographie (plus de 60kg avec la CCD, la monture et plein
            d’autres choses), je commence à regarder mes images. J’y ai
            notamment réalisé une acquisition en bande étroite sur le Grand
            Nuage de Magellan (SHO). Et en examinant l’image, j’ai trouvé une
            petite tache bleue suspecte aux coordonnées suivantes : 05:40:54.77
            -69:26:46.0
          </p>
          <p>
            J’ai vérifié dans Aladin et aucun objet n’apparaît à ces coordonnées
            dans Simbad. J’ai interrogé le site de l’IRSA et n’ai rien trouvé
            non plus dans toutes longueurs d’ondes (même après des « stretchs »
            réalisés sur les images). Après multiples vérifications, je suis sûr
            d’être à la bonne position car la petite boule bleue se trouve à
            côté de NGC 2091. J’ai ensuite examiné mes acquisitions : il ne
            s’agit pas d’un artefact de traitement ni d’un rayon cosmique.
          </p>
          <p>
            La candidate apparaît un peu en Ha, beaucoup en [OIII] et pas du
            tout en [SII]. Tous mes filtres sont des filtres ultra sélectifs 3nm
            (Astrodon).
          </p>
          <p>
            De plus, ma compagne, qui était là-bas en même temps que moi avec
            son matériel (monture + CCD aussi) a également pointé le Grand Nuage
            de Magellan. Après vérification de son image Ha finale, la candidate
            apparait également.
          </p>
          <p>
            En espérant une réponse positive de votre part et que l’objet n’ai
            pas déjà été trouvé (je tente ma chance :) !)
          </p>
          <p>
            Je reste bien sûr à votre disposition pour toute demande
            d’informations complémentaires.
          </p>
          <p>Très cordialement,</p>
          <p>Bastien Foucher</p>
          <p>»</p>
        </div>
        <p>Très gentiment et très rapidement, Pascal me répond :</p>
        <div className="conteneur-italique">
          <p>«</p>
          <p>Salut Bastien</p>
          <p>
            Ça se présente bien. À ma connaissance, il n’y a pas de candidate
            dans cette zone. Peux-tu me communiquer le crop de l’image [OIII] et
            Halpha de l’objet ?
          </p>
          <p>A+ Pascal</p>
          <p>»</p>
        </div>
      </Section>
      <Section titre="Confirmation de la découverte">
        <p>
          Finalement, après envoi des éléments et une ou deux nuits sans trop
          dormir, voici le courriel tant attendu :
        </p>
        <div className="conteneur-italique">
          <p>«</p>
          <p>Bonsoir Bastien</p>
          <p>Ok pour les images, merci.</p>
          <p>
            Dommage que ton image [OIII] soit réalisée en binning 2x2 (perte de
            résolution), mais ce n’est pas grave, la détection est faite.
          </p>
          <p>
            Sur les images DSS2, on peut voir également du signal sur l’image
            bleue principalement et un peu de signal sur l’image rouge. Le
            signal bleu ressort sur l’image couleur.
          </p>
          <p>
            Absence de signal sur l’image Ir, ce qui est bon signe. Rien de
            particulier dans l’Ir moyen avec WISE.
          </p>
          <p>
            Je baptise cet objet Fo 1 (05:40:54.77 -69:26:46.0) et je l’inclus
            dans la table I comme NP probable, félicitations !
          </p>
          <p>
            Je vais bientôt faire un mail à Quentin Parker qui regroupera
            quelques candidates intéressantes répertoriées dernièrement, dont la
            tienne.
          </p>
          <p>Amicalement</p>
          <p>Pascal</p>
          <p>»</p>
        </div>
        <p>
          Et voilà… me voici maintenant découvreur de nébuleuses planétaires.
          J’ai choisi de renommer ma découverte de Fo 1 en FoGl 1, en ajoutant
          le nom de ma compagne sans laquelle je ne serais jamais allé au Chili
          à deux reprises et qui a surtout supporté pendant de longues nuits
          tous mes états d’âme astrophotographiques :-)
        </p>
      </Section>
      <Section titre="Observations avec un télescope de 1 mètre">
        <p>
          La découverte a été réalisée en juin 2018. Mi-septembre 2018, le temps
          que le Grand Nuage de Magellan refasse son apparition dans le ciel
          étoilé austral, je décide de réaliser des acquisitions avec un
          télescope de 1 mètre de diamètre (6,80m de focale) pour voir FoGl 1 un
          peu plus en détails.
        </p>
        <p>
          Après empilement des images, l’aspect de disque, caractéristique d’une
          nébuleuse planétaire, apparaît nettement. On observe également des
          zones plus denses en périphérie de la nébuleuse.
        </p>
        <Image
          image={obtenirImage(images, "articlesFogl1Telescope1m")}
          langue={page.langue}
        />
        <p>
          On y observe également des zones plus denses en périphérie du disque
          (à gauche et à droite).
        </p>
      </Section>
      <Section titre="La suite">
        <p>
          Pour le moment, FoGl 1 ne possède qu’un statut de candidate. En effet,
          rien ne prouve qu’il s’agit réellement d’une nébuleuse planétaire :
          seules des mesures spectrographiques (détermination des gaz
          constituant un objet céleste par analyse de la lumière qu’il émet),
          menées par des personnes compétentes, permettront de déterminer sa
          nature exacte.
        </p>
        <p>
          Deux statuts existent pour qualifier les découvertes de nébuleuses
          planétaires :
        </p>
        <ul>
          <li>
            Les nébuleuses planétaires « possibles » : elles ont un aspect de
            disque avec une étoile bleue centrale ;
          </li>
          <li>
            Les nébuleuses planétaires « candidate » : ce sont des nébuleuses
            planétaires « possibles » mais présentant en plus une image en
            [OIII] brillante. Il s’agit de la classification pour le moment
            retenue pour FoGl 1.
          </li>
        </ul>
        <p>
          D’après Pascal Le Dû, déterminer la nature réelle de FoGl 1 pourra
          prendre du temps, le travail de confirmation des nébuleuses
          planétaires candidates étant très important dans l’hémisphère Sud.
        </p>
        <p>
          Aujourd’hui encore, je ne comprends toujours pas comment cette petite
          nébuleuse n’avait pas été découverte. Elle était là, très bien cachée,
          au beau milieu d’une des nébuleuses les plus photographiées, sous les
          yeux de tous. Il fallait juste utiliser la bonne technique d’imagerie
          pour la débusquer.
        </p>
      </Section>
      <Section titre=" Mise à jour 13/03/22">
        <p>
          L’analyse spectrale est disponible{" "}
          <Lien urlExterne="http://planetarynebulae.net/tableau_spectres/FoGl_1_fiche.pdf">
            ici
          </Lien>
          .
        </p>
        <Image
          image={obtenirImage(images, "ngc2070Tarentule")}
          langue={page.langue}
          legende="NGC 2070, la nébuleuse de la Tarentule en palette « Hubble ». Au centre de l’image se trouve un tout petit disque bleu : il s’agit FoGl 1."
        />
      </Section>
    </LayoutTutorielFR>
  )
}
